import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext
} from 'react-hook-form'
import FieldErrorMessage from '../FieldErrorMessage'
import Input from './Input'
import 'rc-slider/assets/index.css'
import RcSlider from 'rc-slider'
import { cn } from '~/lib/utils'
import FieldMessage from '../FieldMessage'

type Props = {
  name: string
  label?: string
  min?: number
  max?: number
  unit?: string
  step?: string
  rules?: RegisterOptions<FieldValues>
  wide?: boolean
  disabled?: boolean
  infoMessage?: string
}

const Slider = ({
  name,
  label,
  min,
  max,
  unit,
  step,
  rules,
  wide,
  disabled,
  infoMessage
}: Props) => {
  const {
    control,
    setValue,
    formState: { errors }
  } = useFormContext()

  if (unit === undefined) unit = ''

  return (
    <div className="col-span-2 max-w-[356px]">
      {label && (
        <label
          className="mb-2 flex w-full text-sm font-bold uppercase"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div className="flex items-center gap-6">
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field: { value, onChange } }) => (
            <div className="mb-2 w-36 flex-grow">
              <RcSlider
                className={cn(
                  'h-4 py-[7px] [&>.rc-slider-handle-dragging]:!shadow-none [&>.rc-slider-step]:h-0.5',
                  disabled && 'bg-transparent'
                )}
                onChange={(value) => {
                  if (typeof value === 'number') {
                    onChange(Math.round(value * 10) / 10)
                    setValue(name, Math.round(value * 10) / 10)
                  }
                }}
                value={value}
                min={min}
                max={max}
                styles={{
                  handle: {
                    width: '16px',
                    height: '16px',
                    backgroundColor:
                      value === undefined ? '#BFBFBF' : '#808080',
                    border: 'none',
                    opacity: 1,
                    top: 5
                  },
                  track: {
                    width: '100%',
                    height: 2,
                    backgroundColor: '#BFBFBF'
                  },
                  rail: {
                    width: '100%',
                    height: 2,
                    backgroundColor: '#BFBFBF'
                  }
                }}
                disabled={disabled}
              />
            </div>
          )}
        />
        <Input
          name={name}
          unit={unit}
          type="number"
          step={step}
          rules={rules}
          hideError={true}
          width={cn('w-16', wide && 'w-[108px]')}
          className="remove-number-arrows"
          disabled={disabled}
        />
      </div>
      <FieldErrorMessage
        name={name}
        errors={errors}
      />
      {Object.keys(errors).length === 0 && infoMessage && (
        <FieldMessage message={infoMessage} />
      )}
    </div>
  )
}

export default Slider
