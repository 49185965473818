import { useState } from 'react'
import { Autocomplete } from '@react-google-maps/api'
import { StoreState, useBoundStore } from '~/store'
import { shallow } from 'zustand/shallow'
import Input from '../fields/Input'
import { useTranslation } from 'react-i18next'

type Props = {
  setZoom: (zoom: number) => void
  handleAddressChange: (address: string, lat: number, lng: number) => void
}

const AutoCompletePosition = ({ setZoom, handleAddressChange }: Props) => {
  const { t } = useTranslation()
  const [autoComplete, setAutoComplete] =
    useState<google.maps.places.Autocomplete>()
  const [fetched, setFetched] = useState(false)

  const { conditions, updateConditions } = useBoundStore(
    (state: StoreState) => ({
      conditions: state.conditions,
      updateConditions: state.updateConditions
    }),
    shallow
  )

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutoComplete(autocomplete)
  }

  const { latitude, longitude } = conditions

  const onPlaceChanged = () => {
    const place = autoComplete?.getPlace()
    if (place !== undefined && place.formatted_address && place.geometry) {
      const formattedAddress = place.formatted_address
      const location = place.geometry.location
      setZoom(14)
      handleAddressChange(
        formattedAddress,
        location?.lat() || latitude,
        location?.lng() || longitude
      )
    }
    if (fetched) {
      setFetched(false)
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateConditions({
      ...conditions,
      address: event.target.value
    })
  }

  const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (autoComplete?.getPlace().place_id === undefined) {
        const pacItemQuery = document.getElementsByClassName('pac-item-query')
        if (pacItemQuery.length === 0 || fetched) {
          return
        }
        const next = pacItemQuery[0].nextSibling
        let inputText = pacItemQuery[0].textContent
        if (next?.textContent !== '') {
          inputText += `, ${next?.textContent}`
        }
        console.log({ inputText })
        const attrContainer = document.createElement('div')
        const places = new window.google.maps.places.PlacesService(
          attrContainer
        )
        places.findPlaceFromQuery(
          {
            query: inputText ?? '',
            fields: ['formatted_address', 'geometry']
          },
          (result) => {
            if (result && result.length > 0) {
              const formattedAddress = result[0].formatted_address || ''
              const location = result[0].geometry?.location
              setZoom(14)
              handleAddressChange(
                formattedAddress,
                location?.lat() || latitude,
                location?.lng() || longitude
              )
              setFetched(true)
            }
          }
        )
      }
    }
  }

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      options={{
        componentRestrictions: { country: 'se' },
        types: ['address'],
        fields: ['formatted_address', 'geometry']
      }}
      className="col-span-full"
    >
      <Input
        label={t('Adress')}
        type="text"
        name="address"
        placeholder={t('Sök på din adress ...')}
        onChange={handleInputChange}
        onKeyDown={handleEnterPress}
      />
    </Autocomplete>
  )
}

export default AutoCompletePosition
