import { z, t } from '~/lib/i18n'
import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { shallow } from 'zustand/shallow'
import { Button } from '~/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '~/components/ui/form'
import { Input } from '~/components/ui/input'
import { handleSaveResponseData, saveConfiguration } from '~/lib/utils'
import { StoreState, useBoundStore } from '~/store'
import { AxiosResponse } from 'axios'

const FormSchema = z.object({
  name: z.string()
})

type Props = {
  onFormSubmit: () => void
  submitButtonText: string
  hideName?: boolean
}

const SaveNameProjectForm = ({
  submitButtonText,
  onFormSubmit,
  hideName
}: Props) => {
  const { conditions, updateConditions, setIsEdited, setTriggerDialog } =
    useBoundStore(
      (state: StoreState) => ({
        conditions: state.conditions,
        updateConditions: state.updateConditions,
        setIsEdited: state.setIsEdited,
        setTriggerDialog: state.setTriggerDialog
      }),
      shallow
    )

  const [isLoading, setIsLoading] = useState(false)

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: conditions.name ?? ''
    }
  })

  function onSubmit(data: z.infer<typeof FormSchema>) {
    if (conditions.name !== data.name) {
      updateConditions({ ...conditions, name: data.name })
    }
    setIsLoading(true)
    setTimeout(() => {
      saveConfiguration(handleSaveResponse, handleFailedResponse)
    }, 1000)
  }

  const handleSaveResponse = (response: AxiosResponse) => {
    setIsLoading(false)
    setIsEdited(false)
    handleSaveResponseData(response)
    onFormSubmit()
  }

  const handleFailedResponse = (response: AxiosResponse) => {
    setIsLoading(false)
    setTriggerDialog('SomethingWentWrongDialog')
    console.log('failed response', response)
    onFormSubmit()
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-10"
      >
        {hideName ? null : (
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('Namn (Valfritt)')}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t('Namn på projekt')}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        <div className="flex justify-end">
          <Button
            type="submit"
            disabled={isLoading}
            isloading={isLoading}
          >
            {submitButtonText}
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default SaveNameProjectForm
