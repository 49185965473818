import { z, t } from '~/lib/i18n'
import { Trans } from 'react-i18next'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import Input from '~/components/form/fields/Input'
import Button from '~/components/buttons/Button'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '~/store'
import { zodResolver } from '@hookform/resolvers/zod'
import { getRoofShapeImage } from '~/utils/conditions'
import Slider from '~/components/form/fields/Slider'
import React, { useEffect } from 'react'
import Info from '~/components/Info'
import { getRoofPropertiesFormDevDefaultValues } from '~/lib/utils'

const validationSchema = z.object({
  ridgeHeight: z
    .number()
    .min(0.01, t('Värdet måste vara större än 0'))
    .max(100),
  roofSlope: z
    .number()
    .min(0, t('Värdet måste vara större eller lika med 0'))
    .max(10, t('Värdet måste vara mindre eller lika med 10')),
  roofShape: z.string({ invalid_type_error: t('Takform är obligatorisk') }),
  roofMeasurementA: z
    .number({ invalid_type_error: t('Obligatorisk') })
    .min(0.1, t('Värdet måste vara större än 0')),
  roofMeasurementB: z
    .number({ invalid_type_error: t('Obligatorisk') })
    .min(0.1, t('Värdet måste vara större än 0')),
  orientationFromSouth: z
    .number({ invalid_type_error: t('Obligatorisk') })
    .min(-180, t('Värdet måste vara större eller lika med -180'))
    .max(180, t('Värdet måste vara mindre eller lika med 180'))
})

type ValidationSchema = z.infer<typeof validationSchema>

type Props = {
  closeSection: () => void
  isOpen: boolean
}

const RoofPropertiesForm = React.memo(({ closeSection, isOpen }: Props) => {
  const {
    roof,
    conditions,
    isRoofPropertiesDataValid,
    isPositionDataValid,
    isRoofMaterialDataValid,
    updateRoof,
    updateConditions,
    setShowConditions,
    setIsRoofPropertiesDataValid,
    setShowPanelSettings
  } = useBoundStore(
    (state: StoreState) => ({
      roof: state.computed.currentRoof,
      conditions: state.conditions,
      isRoofPropertiesDataValid: state.computed.isRoofPropertiesDataValid,
      isPositionDataValid: state.isPositionDataValid,
      isRoofMaterialDataValid: state.computed.isRoofMaterialDataValid,
      updateRoof: state.updateRoof,
      updateConditions: state.updateConditions,
      setShowConditions: state.setShowConditions,
      setIsRoofPropertiesDataValid: state.setIsRoofPropertiesDataValid,
      setShowPanelSettings: state.setShowPanelSettings
    }),
    shallow
  )

  if (!roof) return null

  const devDefaultValues = getRoofPropertiesFormDevDefaultValues()

  let defaultValues = {
    ridgeHeight: roof.ridgeHeight / 1000 || undefined,
    roofSlope: roof.slope || undefined,
    roofShape: roof.shape,
    roofMeasurementA: roof.measurementA / 1000 || undefined,
    roofMeasurementB: roof.measurementB / 1000 || undefined,
    orientationFromSouth: roof.orientationFromSouth
  }

  if (isRoofPropertiesDataValid !== true) {
    defaultValues.roofSlope = undefined
    defaultValues = { ...defaultValues, ...devDefaultValues }
  }

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues
  })

  useEffect(() => {
    reset()
  }, [roof, conditions])

  const {
    watch,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = form

  const roofPropertiesFormData = z.object({
    ridgeHeight: z.number().min(1),
    roofSlope: z.number().min(0).max(10),
    roofMeasurementA: z.number().min(0.1),
    roofMeasurementB: z.number().min(0.1),
    orientationFromSouth: z.number().min(-180).max(180)
  })

  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    let validData = null
    try {
      validData = roofPropertiesFormData.parse(data)
      updateConditions({
        ...conditions
      })
      updateRoof({
        ...roof,
        ridgeHeight: validData.ridgeHeight * 1000,
        slope: validData.roofSlope,
        measurementA: validData.roofMeasurementA * 1000,
        measurementB: validData.roofMeasurementB * 1000,
        orientationFromSouth: validData.orientationFromSouth
      })
      closeSection()
      if (
        isPositionDataValid &&
        isRoofMaterialDataValid &&
        !isRoofPropertiesDataValid
      ) {
        setShowConditions(false)
        setTimeout(() => {
          setShowPanelSettings(true)
        }, 450)
      }
      setIsRoofPropertiesDataValid(true)
    } catch (error) {
      if (error instanceof z.ZodError) {
        console.log(error.issues)
      }
    }
  }

  const resetForm = () => {
    reset(defaultValues)
  }

  useEffect(() => {
    resetForm()
  }, [roof, isOpen])

  const roofShape = watch('roofShape')

  return (
    <FormProvider {...form}>
      <form
        className="col-span-full grid h-auto grid-cols-4 gap-4 overflow-visible pt-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          name="ridgeHeight"
          type="number"
          label={t('Nockhöjd') || ''}
          unit={t('m')}
          step="0.01"
          columnPosition={{ span: 2 }}
        />
        <Slider
          name="roofSlope"
          label={t('TAKLUTNING (0-10°)') || ''}
          min={0}
          max={10}
          unit="°"
        />
        <h3 className="heading-s col-span-full">
          {t('Mått tilltänkt taksida')}
        </h3>
        <div className="col-span-full mb-6 grid grid-cols-3 grid-rows-3 gap-x-12 gap-y-2">
          {roofShape !== null && roofShape !== undefined ? (
            <>
              <div className="col-span-1 row-span-1 flex flex-nowrap items-center">
                <div className="heading-s mr-4">A</div>
                <Input
                  name="roofMeasurementA"
                  type="number"
                  // label="A"
                  unit={t('m')}
                  step="0.1"
                  className="w-full"
                />
              </div>
              <div className="col-span-1 row-start-2 flex flex-nowrap items-center">
                <div className="heading-s mr-4">B</div>
                <Input
                  name="roofMeasurementB"
                  type="number"
                  // label="B"
                  unit="m"
                  step="0.1"
                  className="w-full"
                />
              </div>
            </>
          ) : null}
          <div className="col-span-2 col-start-2 row-span-3">
            {roofShape !== null && roofShape !== undefined && (
              <img src={getRoofShapeImage(roofShape)} />
            )}
          </div>
        </div>
        <h3 className="heading-s col-span-full">
          <Trans i18nKey="roof_orientation_from_south">
            Takorientering från söder{' '}
            <span className="font-normal">(valfritt)</span>
          </Trans>
          <Info
            id="roof_orientation_from_south"
            text={t(
              'Ange takets vinkel i förhållande till söder för att underlätta placeringen av solpaneler och optimera solinstrålningen. Söder = 0 grader, väster = 90 grader, öster = -90 grader och norr = 180 grader'
            )}
          />
        </h3>
        <Input
          name="orientationFromSouth"
          type="number"
          label={t('Vinkel') || ''}
          unit={t('°')}
          step="1"
          columnPosition={{ span: 2 }}
        />
        {isRoofPropertiesDataValid ? (
          <div className="col-span-full flex justify-end">
            <Button>{isDirty ? t('Uppdatera') : t('Klar')}</Button>
          </div>
        ) : (
          <div className="col-span-full flex justify-end">
            <Button>
              {isPositionDataValid && isRoofMaterialDataValid
                ? t('Klar')
                : t('Uppdatera')}
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  )
})

export default RoofPropertiesForm
