import { t } from '~/lib/i18n'

import placeholder from './../../assets/56.png'
import concrete from './../../assets/takskydd_betong.jpg'
import brick from './../../assets/takskydd_tegel.jpg'
import profiledSheetMetal from './../../assets/profilerad_plat.jpg'
import tileProfiledSheetMetal from './../../assets/tegelprofilerad_plat.jpg'

import corrugatedTinMetal from './../../assets/falsat-plattak.png'
import flatRoof from './../../assets/slata-tak.png'
import standingSeamMount from './../../assets/falsfaste.jpg'

import brickBasePlate from './../../assets/tegel_fotplatta.jpg'
import brickLathMount from './../../assets/tegel_laktfaste.jpg'
import concreteLathMount from './../../assets/betong_laktfaste.jpg'
import concreteBasePlate from './../../assets/betong_fotplatta.jpg'
import concreteTileLathMount from './../../assets/PF1400-lakt.jpg'

import shortRail from './../../assets/short_rail.jpg'
import longRail from './../../assets/long_rail.jpg'

import sealingPlateElevated from './../../assets/tatplat_forhojd.jpg'
import sealingPlateOpti from './../../assets/tatplat_opti.jpg'

import terrain0 from './../../assets/terrain_1.svg'
import terrain1 from './../../assets/terrain_2.svg'
import terrain2 from './../../assets/terrain_3.svg'
import terrain3 from './../../assets/terrain_4.svg'
import terrain4 from './../../assets/terrain_5.svg'

import butterfly from './../../assets/motfalls.svg'
import pent from './../../assets/pulpet.svg'
import gable from './../../assets/sadel.svg'

import rectangleSmall from './../../assets/roof_shape_small_rectangle.png'
import angle1Small from './../../assets/roof_shape_small_angle_1.png'
import rhomb1Small from './../../assets/roof_shape_small_rhomb_1.png'
import trapezoidSmall from './../../assets/roof_shape_small_trapezoid_1.png'

import hotGalvanized from './../../assets/varmforzinkat.png'
import blackLacquered from './../../assets/svartlackerat.png'

const handleAttachmentImage = (attachment: string, values: any): string => {
  switch (attachment) {
    case 'base_plate':
      return values.covering === 'concrete_tile'
        ? concreteBasePlate
        : brickBasePlate
    case 'lath_mount':
      return values.covering === 'concrete_tile'
        ? concreteLathMount
        : brickLathMount
    case 'tile_lath_mount':
      return values.covering === 'concrete_tile'
        ? concreteTileLathMount
        : concreteTileLathMount
    default:
      return placeholder
  }
}

const handleAttachment = (
  covering: string,
  topDistance?: number
): formOptionsItemType[] => {
  switch (covering) {
    case 'concrete_tile':
    case 'brick_tile':
      return [
        {
          value: 'base_plate',
          label: t('Fotplatta'),
          image: handleAttachmentImage
        },
        {
          value: 'lath_mount',
          label: t('Läktfäste'),
          image: handleAttachmentImage
        },
        {
          value: 'tile_lath_mount',
          label: t('Pannfäste - läkt'),
          image: handleAttachmentImage
        }
      ]
    case 'corrugated_tin_metal':
      return [
        {
          value: 'standing_seam_mount',
          label: t('Falsfäste'),
          image: standingSeamMount
        }
      ]
    case 'profiled_sheet_metal':
      return [
        {
          value: 'long_rail',
          label: t('Lång skena'),
          image: longRail,
          rules: {
            disabled: topDistance !== undefined ? topDistance > 300 : false
          },
          info: 'Max avstånd toppar 300mm'
        },
        {
          value: 'short_rail',
          label: t('Kort skena'),
          image: shortRail,
          rules: {
            disabled: topDistance !== undefined ? topDistance > 150 : false
          },
          info: t('Max avstånd toppar 150mm')
        }
      ]
    case 'tile_profiled_sheet_metal':
      return [
        {
          value: 'long_rail',
          label: t('Lång skena'),
          image: longRail,
          rules: {
            disabled: topDistance !== undefined ? topDistance > 300 : false
          },
          info: t('Max avstånd toppar 300mm')
        }
      ]
    case 'flat':
      return [
        {
          value: 'sealing_plate_flat',
          label: t('Tätplåt Opti'),
          image: sealingPlateOpti
        },
        {
          value: 'sealing_plate_elevated',
          label: t('Tätplåt förhöjd'),
          image: sealingPlateElevated
        }
      ]
    default:
      return [{ value: '', label: t('Ingen'), image: placeholder }]
  }
}

export type formOptionsType = {
  terrain: formOptionsItemType[]
  roofType: formOptionsItemType[]
  roofShape: formOptionsItemType[]
  roofCovering: formOptionsItemType[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attachment: (...args: any[]) => formOptionsItemType[]
  lathDimension: formOptionsItemType[]
  sheetMetalMaterial: formOptionsItemType[]
  surfaceTreatment: formOptionsItemType[]
  lifeSpan: formOptionsItemType[]
  safetyClass: formOptionsItemTypeSelect[]
}

export const formOptions: formOptionsType = {
  terrain: [
    {
      value: 0,
      label: t('Terrängtyp 0'),
      image: terrain0,
      info: t('Havs- eller kustområde exponerat för öppet hav.')
    },
    {
      value: 1,
      label: t('Terrängtyp I'),
      image: terrain1,
      info: t(
        'Sjö eller plant och horisontellt område med försumbar vegetation och utan hinder.'
      )
    },
    {
      value: 2,
      label: t('Terrängtyp II'),
      image: terrain2,
      info: t(
        'Område med låg vegetation som gräs och enstaka hinder (träd, byggnader) med minsta inbördes avstånd lika med 20 gånger hindrets höjd.'
      )
    },
    {
      value: 3,
      label: t('Terrängtyp III'),
      image: terrain3,
      info: 'Område täckt med vegetation eller byggnader eller med enstaka hinder med största inbördes avstånd lika med 20 gånger hindrets höjd (till exempel byar, förorter, skogsmark).'
    },
    {
      value: 4,
      label: t('Terrängtyp IV'),
      image: terrain4,
      info: t(
        'Område där minst 15 % av arean är bebyggd och där byggnadernas medelhöjd är > 15 m.'
      )
    }
  ],
  roofType: [
    { value: 'gable', label: t('Sadel'), image: gable },
    { value: 'pent', label: t('Pulpet'), image: pent },
    { value: 'butterfly', label: t('Motfalls'), image: butterfly }
  ],
  roofShape: [
    { value: 'rectangle', label: t('Rektangel'), image: rectangleSmall },
    {
      value: ['angle1', 'angle2'],
      label: t('Vinkel'),
      image: angle1Small
    },
    { value: ['rhomb1', 'rhomb2'], label: t('Romb'), image: rhomb1Small },
    {
      value: 'trapezoid',
      label: t('Trapets'),
      image: trapezoidSmall
    }
  ],
  roofCovering: [
    { value: 'concrete_tile', label: t('Betongpannor'), image: concrete },
    { value: 'brick_tile', label: t('Tegelpannor'), image: brick },
    {
      value: 'corrugated_tin_metal',
      label: t('Falsat plåttak'),
      image: corrugatedTinMetal
    },
    {
      value: 'profiled_sheet_metal',
      label: t('Profilerat plåttak'),
      image: profiledSheetMetal
    },
    {
      value: 'tile_profiled_sheet_metal',
      label: t('Tegelprofilerat plåttak'),
      image: tileProfiledSheetMetal
    },
    { value: 'flat', label: t('Släta tak'), image: flatRoof }
  ],
  attachment: handleAttachment,
  lathDimension: [
    { value: '45x70', label: t('45x70 mm') },
    { value: '45x60', label: t('45x60 mm') },
    { value: '45x45', label: t('45x45 mm') },
    { value: '34x70', label: t('34x70 mm') },
    { value: '34x45', label: t('34x45 mm') },
    { value: '28x70', label: t('28x70 mm') },
    { value: '25x48', label: t('25x48 mm') }
  ],
  sheetMetalMaterial: [
    { value: 'steel_0.5', label: t('Stålplåt min. 0,5 mm') },
    { value: 'steel_0.4', label: t('Stålplåt min. 0,4 mm') },
    { value: 'aluminium_0.7', label: t('Aluminiumplåt min 0,7 mm') },
    { value: 'aluminium_0.5', label: t('Aluminiumplåt min 0,5 mm') }
  ],
  surfaceTreatment: [
    { value: 'S', label: t('Svartlackerad'), image: blackLacquered },
    { value: '', label: t('Varmförzinkad'), image: hotGalvanized }
  ],
  lifeSpan: [
    { label: '30 år', value: '30' },
    { label: '50 år', value: '50' }
  ],
  safetyClass: [
    { label: '1', value: '1', rules: { disabled: true }, isDisabled: true },
    { label: '2', value: '2' },
    { label: '3', value: '3' }
  ]
}

import PANELMONTERING_1 from './../../assets/PANELMONTERING_1.svg'
import PANELMONTERING_1_2 from './../../assets/PANELMONTERING_1_2.svg'
import PANELMONTERING_2 from './../../assets/PANELMONTERING_2.svg'
import PANELMONTERING_3 from './../../assets/PANELMONTERING_3.svg'
import PANELMONTERING_3_2 from './../../assets/PANELMONTERING_3_2.svg'
import PANELMONTERING_4 from './../../assets/PANELMONTERING_4.svg'

export const panelMountingOptions = [
  { value: '90-portrait', image: PANELMONTERING_1 },
  { value: '0-portrait', image: PANELMONTERING_2 },
  { value: '90-landscape', image: PANELMONTERING_3 },
  { value: '0-landscape', image: PANELMONTERING_4 }
]

export const corrugatedTinMetalPanelMountingOptions = [
  { value: '90-portrait', image: PANELMONTERING_1 },
  { value: '0-portrait', image: PANELMONTERING_2, rules: { disabled: true } },
  { value: '90-landscape', image: PANELMONTERING_3 },
  { value: '0-landscape', image: PANELMONTERING_4 }
]

export const shortRailPanelMountingOptions = [
  { value: '90-portrait', image: PANELMONTERING_1_2 },
  { value: '90-landscape', image: PANELMONTERING_3_2 }
]
