import { z, t } from '~/lib/i18n'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Input from '~/components/form/fields/Input'
import Button from './buttons/Button'
import Checkbox from '~/components/form/fields/Checkbox'
import { useState } from 'react'
import { authClient } from '../http/api'
import { AxiosResponse } from 'axios'
import { StoreState, useBoundStore } from '../store'
import * as Sentry from '@sentry/react'
import ErrorMessage from './ErrorMessage'

const validationSchema = z.object({
  email: z.string().email(),
  password: z.string().min(1, t('Fältet är obligatoriskt') || ''),
  remember: z.boolean()
})

type ValidationSchema = z.infer<typeof validationSchema>

const LoginModalForm = () => {
  const { setUser, setShowDialog } = useBoundStore((state: StoreState) => ({
    setUser: state.setUser,
    setShowDialog: state.setShowDialog
  }))

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema)
  })

  const [disableSubmit, setDisableSubmit] = useState(false)
  const [showLoginError, setShowLoginError] = useState(false)
  const [loginErrorMessage, setLoginErrorMessage] = useState('')
  const [verificationUrl, setVerificationUrl] = useState('')

  const { handleSubmit } = form

  const handleApiResponse = (res: AxiosResponse) => {
    setDisableSubmit(false)
    if (res.data.status) {
      setUser(res.data.user)
      setShowDialog('LoginSuccessDialog')
    }
  }

  const handleFailedApiResponse = (res: any) => {
    if (res.response?.data?.errors.length === 0) {
      Sentry.captureException(res)
    }
    setShowLoginError(true)
    setLoginErrorMessage(
      res.response?.data?.errors[0] ??
        t('Något gick fel, vänligen försök igen senare')
    )
    if (typeof res?.response?.data?.url === 'string') {
      setVerificationUrl(res.response.data.url)
    }
    setDisableSubmit(false)
  }

  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    setShowLoginError(false)
    setLoginErrorMessage('')
    setDisableSubmit(true)
    authClient
      .post('/login', data)
      .then(handleApiResponse)
      .catch(handleFailedApiResponse)
  }

  return (
    <>
      <div className="w-[640px] bg-white px-20">
        {showLoginError ? (
          <ErrorMessage>
            <p>
              {loginErrorMessage}
              {verificationUrl.length > 0 ? (
                <>
                  &nbsp;
                  <a href={verificationUrl}>
                    {t('Skicka ny verifieringslänk')}
                  </a>
                </>
              ) : null}
            </p>
          </ErrorMessage>
        ) : null}
        <FormProvider {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mb-4"
          >
            <div className="mb-10 flex flex-col gap-6">
              <Input
                label={t('E-Postadress') || ''}
                name="email"
                type="email"
              />
              <Input
                label={t('Lösenord') || ''}
                name="password"
                type="password"
              />
              <div className="item flex justify-between text-base font-light">
                <Checkbox
                  name="remember"
                  label={t('Håll mig inloggad') || ''}
                />
                <a href="#forgotten-password">{t('Glömt lösenord?')}</a>
              </div>
            </div>
            <Button
              className="mb-2 w-full"
              disabled={disableSubmit}
            >
              {t('Logga in')}
            </Button>
          </form>
        </FormProvider>
      </div>
    </>
  )
}

export default LoginModalForm
