import { z, t } from '~/lib/i18n'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { shallow } from 'zustand/shallow'
import { Button } from '~/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '~/components/ui/form'
import { Input } from '~/components/ui/input'
import { StoreState, useBoundStore } from '~/store'

const FormSchema = z.object({
  name: z.string()
})

type Props = {
  onFormSubmit: (name: string) => void
  isLoading?: boolean
}

export function NameProjectForm({ isLoading, onFormSubmit }: Props) {
  const { conditions, project } = useBoundStore(
    (state: StoreState) => ({
      conditions: state.conditions,
      project: state.computedProject.currentProject
    }),
    shallow
  )

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: project?.name ? project?.name : conditions.name
    }
  })

  function onSubmit(data: z.infer<typeof FormSchema>) {
    onFormSubmit(data.name)
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-10"
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('Namn (Valfritt)')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('Namn på projekt')}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-end">
          <Button
            isloading={isLoading}
            type="submit"
          >
            {t('Klar')}
          </Button>
        </div>
      </form>
    </Form>
  )
}
