import { z, t } from '~/lib/i18n'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '~/components/ui/button'
import { StoreState, useBoundStore } from '~/store'
import { AxiosResponse } from 'axios'
import { FormProvider } from 'react-hook-form'
import Input from '../../components/form/fields/Input'
import { calculatePanelWeight, cn, savePanel } from '~/lib/utils'
import { defaultPanelInfoValues } from '~/slices/ConfiguratorSlice'
import { v4 as uuidv4 } from 'uuid'

const FormSchema = z.object({
  uid: z.string(),
  name: z.string().min(1, { message: t('Ange panelnamn') }),
  width: z.number().min(1, { message: t('Ange bredd') }),
  height: z.number().min(1, { message: t('Ange höjd') }),
  weight: z.number()
})

type Props = {
  handleSaveResponse: (res: AxiosResponse) => void
  handleFailedResponse: (res: AxiosResponse) => void
  onClose: () => void
}

const CreateEditPanelForm = ({
  handleSaveResponse,
  handleFailedResponse,
  onClose
}: Props) => {
  const { currentPanel } = useBoundStore((state: StoreState) => ({
    currentPanel: state.currentPanel
  }))

  const [_, setSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [shouldCalculateWeight, setShouldCalculateWeight] = useState(false)

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      uid: currentPanel?.uid ?? defaultPanelInfoValues.uid,
      name: currentPanel?.name ?? defaultPanelInfoValues.name,
      width: currentPanel?.width ?? defaultPanelInfoValues.width,
      height: currentPanel?.height ?? defaultPanelInfoValues.height,
      weight: currentPanel?.weight ?? defaultPanelInfoValues.weight
    }
  })

  const width = form.watch('width')
  const height = form.watch('height')
  const uid = form.watch('uid')

  useEffect(() => {
    if (shouldCalculateWeight) {
      form.setValue('weight', calculatePanelWeight(width, height))
    }
    setShouldCalculateWeight(true)
  }, [width, height])

  useEffect(() => {
    /**
     * If the user wants to create a new panel,
     * then currentPanel won't exists and uid will not be set
     * */
    if (!uid) {
      form.setValue('uid', uuidv4())
    }
  }, [uid])

  function onSubmit(data: z.infer<typeof FormSchema>) {
    setIsLoading(true)

    setTimeout(() => {
      const validData = FormSchema.parse(data)
      savePanel(
        validData as PanelInfo,
        handleSaveResponse,
        handleFailedResponse
      )
      setSubmitted(true)
      setIsLoading(false)
    }, 1000)
  }

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={cn('grid grid-cols-2 gap-x-4 gap-y-2')}
      >
        <Input
          name="uid"
          type="hidden"
        />
        <Input
          label={t('Namn')}
          name="name"
          className={cn('text-base')}
          placeholder={t('Namn')}
        />
        <Input
          label={t('Bredd')}
          name="width"
          type="number"
          className={cn('col-span-1 text-base')}
          placeholder={t('Bredd')}
          unit={t('mm')}
        />
        <Input
          label={t('Höjd')}
          name="height"
          type="number"
          className={cn('col-span-1 text-base')}
          placeholder={t('Höjd')}
          unit={t('mm')}
        />
        <Input
          label={t('Vikt')}
          name="weight"
          type="number"
          className={cn('col-span-1 mb-8 text-base')}
          placeholder={t('Vikt')}
          unit={t('kg')}
        />

        <div className={cn('col-span-full flex justify-end gap-x-4')}>
          <Button
            type="button"
            variant="ghost"
            onClick={onClose}
            className={cn(
              'col-span-2 border border-grayscale-75 bg-transparent text-grayscale-75 hover:bg-grayscale-75 hover:text-white disabled:text-white'
            )}
          >
            {t('Avbryt')}
          </Button>
          <Button
            type="submit"
            className={cn('col-span-2')}
            isloading={isLoading}
          >
            {t('Spara')}
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}

export default CreateEditPanelForm
