import { zodResolver } from '@hookform/resolvers/zod'
import { z, t } from '~/lib/i18n'
import { FormProvider, useForm } from 'react-hook-form'
import { shallow } from 'zustand/shallow'
import { Button } from '~/components/ui/button'
import { StoreState, useBoundStore } from '~/store'
import NewSelect from './fields/NewSelect'
import Checkbox from './fields/Checkbox'
import { useEffect } from 'react'
import { cn, getCsvUrl } from '~/lib/utils'
import * as Sentry from '@sentry/react'

const FormSchema = z.object({
  documentType: z.string(),
  'project-details': z.boolean(),
  'product-list': z.boolean(),
  roof: z.boolean(),
  'roof-details': z.boolean(),
  'roof-product-list': z.boolean(),
  'roof-position': z.boolean(),
  sections: z.boolean(),
  'section-overview': z.boolean(),
  'section-details': z.boolean(),
  'section-results': z.boolean(),
  'include-prices': z.boolean(),
  documents: z.boolean()
})

type Props = {
  onFormSubmit: () => void
}

export function FormGeneratePDF({ onFormSubmit }: Props) {
  const {
    user,
    project,
    pdfUrl,
    currentProjectReference,
    reference,
    setTriggerDialog
  } = useBoundStore(
    (state: StoreState) => ({
      user: state.user,
      project: state.computedProject.currentProject,
      pdfUrl: state.pdfUrl,
      currentProjectReference: state.currentProjectReference,
      reference: state.reference,
      setTriggerDialog: state.setTriggerDialog
    }),
    shallow
  )

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      documentType: 'full-pdf',
      'project-details': true,
      'product-list': true,
      roof: true,
      'roof-details': true,
      'roof-product-list': true,
      'roof-position': true,
      'section-overview': true,
      sections: true,
      'section-details': true,
      'section-results': true,
      'include-prices': true,
      documents: true
    }
  })

  function onSubmit(data: z.infer<typeof FormSchema>) {
    switch (data.documentType) {
      case 'full-pdf':
      case 'customized-pdf':
        generateAndOpenPdf(data)
        break

      case 'full-csv':
        generateAndDownloadCsv(data)
        break
    }

    onFormSubmit()
  }

  function generateAndOpenPdf(data: z.infer<typeof FormSchema>) {
    if (pdfUrl !== undefined) {
      const url = getPdfUrl(data, pdfUrl)
      window.open(url, '_blank')?.focus()
    } else {
      setTriggerDialog('SomethingWentWrongDialog')
    }
  }

  function generateAndDownloadCsv(data: z.infer<typeof FormSchema>) {
    if (currentProjectReference == undefined && reference == undefined) {
      setTriggerDialog('SomethingWentWrongDialog')
      Sentry.captureMessage(
        'Project reference is undefined when trying to download CSV'
      )

      return
    }

    const projectReference = currentProjectReference
      ? currentProjectReference
      : reference

    window
      .open(getCsvUrl(projectReference!, data['include-prices']), '_blank')
      ?.focus()
  }

  function getPdfUrl(data: z.infer<typeof FormSchema>, pdfUrl: string) {
    const pages: (keyof z.infer<typeof FormSchema>)[] = [
      'project-details',
      'product-list',
      'roof-details',
      'roof-product-list',
      'roof-position',
      'section-overview',
      'section-details',
      'section-results',
      'documents'
    ]

    const selectedPages = pages.filter((page) => data[page]).join(',')
    const allPagesSelected = selectedPages.split(',').length === pages.length

    let downloadUrl = new URL(pdfUrl)
    if (!allPagesSelected) {
      downloadUrl.searchParams.set('pages', selectedPages)
    }
    if (!data['include-prices']) {
      downloadUrl.searchParams.set('hide-prices', 'true')
    }

    return downloadUrl.toString()
  }

  const { watch, setValue } = form

  const documentType = watch('documentType')
  const roof = watch('roof')
  const sections = watch('sections')

  useEffect(() => {
    setValue('roof-details', roof)
    setValue('roof-product-list', roof)
    setValue('roof-position', roof)
    setValue('section-overview', roof)
  }, [roof])

  useEffect(() => {
    setValue('section-details', sections)
    setValue('section-results', sections)
  }, [sections])

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <NewSelect
            name="documentType"
            label={t('Dokument')}
            className={cn(user ? 'mb-6' : 'mb-10')}
            options={[
              {
                value: 'full-pdf',
                label: t('Fullständig rapport (PDF)')
              },
              {
                value: 'customized-pdf',
                label: t('Anpassad rapport (PDF)')
              },
              {
                value: 'full-csv',
                label: t('Artikelspecifikation (CSV)')
              }
            ]}
            rules={{ required: { value: true, message: t('Obligatorisk') } }}
          />
          {documentType === 'customized-pdf' ? (
            <div className={cn('mb-6 flex flex-col gap-1')}>
              <label className={cn('mb-1 text-sm font-bold uppercase')}>
                {t('Kapitel')}
              </label>
              <Checkbox
                name="project-details"
                label={t('Projektinformation')}
              />
              <Checkbox
                name="product-list"
                label={t('Artikelspecifikation')}
              />
              <Checkbox
                name="roof"
                label={t('Tak')}
              />
              <Checkbox
                name="roof-details"
                label={t('Takinformation')}
                className="ml-6"
              />
              {(project?.numberOfRoofs ?? 0) > 1 ? (
                <Checkbox
                  name="roof-product-list"
                  label={t('Artikelspecifikation')}
                  className="ml-6"
                />
              ) : null}
              <Checkbox
                name="roof-position"
                label={t('Position på tak')}
                className="ml-6"
              />
              <Checkbox
                name="section-overview"
                label={t('Sektionsöversikt')}
                className="ml-6"
              />
              <Checkbox
                name="sections"
                label={t('Sektioner')}
              />
              <Checkbox
                name="section-details"
                label={t('Sektionsinformation')}
                className="ml-6"
              />
              {user ? (
                <Checkbox
                  name="section-results"
                  label={t('Resultat')}
                  className="ml-6"
                />
              ) : null}
              <Checkbox
                name="documents"
                label={t('Monteringsanvisningar')}
              />
            </div>
          ) : null}
          <div className={cn('mb-10')}>
            {user && !documentType.includes('csv') ? (
              <>
                <label className={cn('text-sm font-bold uppercase')}>
                  {t('Visning av pris')}
                </label>
                <Checkbox
                  name="include-prices"
                  label={t('Inkludera pris')}
                />
              </>
            ) : null}
          </div>
          <div className="flex justify-end">
            <Button type="submit">{t('Ladda ner')}</Button>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
