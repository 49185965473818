import { z, t } from '~/lib/i18n'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import Input from './fields/Input'
import Radio from './fields/Radio'
import { formOptions } from './formOptions'
import Button from '../buttons/Button'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '../../store'
import { zodResolver } from '@hookform/resolvers/zod'
import Position from './map/Position'
import React, { useEffect, useState } from 'react'
import { solarPanelClient } from '../../http/api'
import { useNavigate } from 'react-router-dom'
import Select from './fields/Select'
import * as Sentry from '@sentry/react'
import { preventEnterKeySubmission } from '~/utils/utils'
import Loader from '~/components/Loader'
import NewSelect from './fields/NewSelect'

const validationSchema = z.object({
  address: z.string().optional().nullable(),
  latitude: z.number(),
  longitude: z.number(),
  snow: z
    .number({ invalid_type_error: t('Värde för snözon är obligatorisk') })
    .multipleOf(0.1)
    .min(1)
    .max(10),
  wind: z
    .number({ invalid_type_error: t('Värde för vindzon är obligatorisk') })
    .min(21)
    .max(35),
  terrain: z.string({ invalid_type_error: t('Terrängtyp är obligatorisk') }),
  lifeSpan: z.string({ invalid_type_error: t('Livslängd är obligatorisk') }),
  // safetyClass: z.object(
  //   { value: z.string(), label: z.string() },
  //   {
  //     invalid_type_error: t('Säkerhetsklass är obligatorisk')
  //   }
  // )
  safetyClass: z.string({
    invalid_type_error: t('Säkerhetsklass är obligatorisk')
  })
})

type ValidationSchema = z.infer<typeof validationSchema>

type Props = {
  closeSection?: () => void
  isOpen: boolean
  openNextSection?: () => void
}

const FormPositionPage = React.memo(({ isOpen }: Props) => {
  const [climateLoadsLoading, setClimateLoadsLoading] = useState(false)
  const navigateTo = useNavigate()
  const {
    conditions,
    updateConditions,
    setIsPositionDataValid,
    setClimateLoadsError
  } = useBoundStore(
    (state: StoreState) => ({
      conditions: state.conditions,
      isPositionDataValid: state.isPositionDataValid,
      updateConditions: state.updateConditions,
      setIsPositionDataValid: state.setIsPositionDataValid,
      setClimateLoadsError: state.setClimateLoadsError
    }),
    shallow
  )

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      address: conditions.address,
      latitude: conditions.latitude,
      longitude: conditions.longitude,
      snow: conditions.snow,
      wind: conditions.wind,
      terrain: conditions.terrain.toString(),
      lifeSpan: conditions.lifeSpan.toString(),
      safetyClass: conditions.safetyClass.toString()
    }
  })

  useEffect(() => {
    reset()
  }, [conditions])

  const resetForm = () => {
    reset({
      address: conditions.address,
      latitude: conditions.latitude,
      longitude: conditions.longitude,
      snow: conditions.snow,
      wind: conditions.wind,
      terrain: conditions.terrain.toString(),
      lifeSpan: conditions.lifeSpan.toString(),
      safetyClass: conditions.safetyClass.toString()
    })
  }

  const { reset, watch, handleSubmit, setValue } = form

  const handleAddressChange = (address: string, lat: number, lng: number) => {
    setValue('address', address, {
      shouldDirty: conditions.address !== address
    })
    setValue('latitude', lat, {
      shouldDirty: conditions.latitude !== latitude
    })
    setValue('longitude', lng, {
      shouldDirty: conditions.longitude !== longitude
    })
  }

  const handleMapInteraction = (_address: string, lat: number, lng: number) => {
    setValue('address', `${latitude}, ${longitude}`)
    setValue('latitude', lat, {
      shouldDirty: conditions.latitude !== latitude
    })
    setValue('longitude', lng, {
      shouldDirty: conditions.longitude !== longitude
    })
  }

  const latitude = watch('latitude')
  const longitude = watch('longitude')
  const address = watch('address')

  const handleClimateLoadsResponse = (res: any) => {
    setValue('snow', res.data.snow, {
      shouldDirty: res.data.snow !== conditions.snow
    })
    setValue('wind', res.data.wind, {
      shouldDirty: res.data.wind !== conditions.wind
    })
    setClimateLoadsError(res.data.messages[0] ?? undefined)
    setClimateLoadsLoading(false)
  }

  useEffect(() => {
    const abortController = new AbortController()
    setClimateLoadsLoading(true)
    solarPanelClient
      .post(
        '/climate-loads',
        { longitude, latitude },
        {
          signal: abortController.signal
        }
      )
      .then(handleClimateLoadsResponse)
      .catch((error) => {
        Sentry.captureException(error)
        setClimateLoadsLoading(false)
      })
    return () => {
      abortController.abort()
    }
  }, [latitude, longitude])

  useEffect(() => {
    resetForm()
  }, [conditions, isOpen])

  const positionFormData = z.object({
    address: z.string().optional().nullable(),
    latitude: z.number(),
    longitude: z.number(),
    snow: z.number().min(1).max(10),
    wind: z.number().min(21).max(35),
    terrain: z.coerce.number(),
    lifeSpan: z.coerce.number(),
    safetyClass: z.string()
  })

  const handleNavigation = (system: Conditions['configurationSystem']) => {
    switch (system) {
      case 'parallel':
        navigateTo('/parallel')
        break
      case 'low':
        navigateTo('/low-slope')
        break
      case 'vertical':
        navigateTo('/vertical')
        break
      default:
        console.log('invalid system ', { system })
        break
    }
  }

  const handleOnSubmit = (data: ValidationSchema) => {
    let validData = null
    try {
      validData = positionFormData.parse(data)
      updateConditions({
        ...conditions,
        ...validData,
        address:
          typeof validData.address === 'string' ? validData.address : undefined,
        safetyClass: parseInt(validData.safetyClass)
      })
      setIsPositionDataValid(true)
      handleNavigation(conditions.configurationSystem)
    } catch (error) {
      console.log({ error })
      if (error instanceof z.ZodError) {
        console.log(error.issues)
      }
    }
  }

  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    handleOnSubmit(data)
  }

  return (
    <FormProvider {...form}>
      <form
        className="col-span-full grid h-auto grid-cols-4 gap-4 overflow-visible pt-8"
        onKeyDown={preventEnterKeySubmission}
      >
        <Position
          isOpen={isOpen}
          address={address || ''}
          coordinates={{ latitude, longitude }}
          handleAddressChange={handleAddressChange}
          handleMapInteraction={handleMapInteraction}
          usePortal={true}
        />
        <Input
          name="latitude"
          type="hidden"
        />
        <Input
          name="longitude"
          type="hidden"
        />
        <Input
          name="snow"
          type="number"
          label={t('Snözon')}
          rules={{
            required: t('Fältet är obligatoriskt'),
            min: 1,
            max: 10
          }}
          unit={climateLoadsLoading ? <Loader /> : 'kN/m²'}
          disabled={climateLoadsLoading}
          step="0.1"
          columnPosition={{ start: 1, end: 3 }}
        />
        <Input
          name="wind"
          type="number"
          label={t('Vindzon')}
          rules={{
            required: t('Fältet är obligatoriskt'),
            min: 21,
            max: 35
          }}
          unit={climateLoadsLoading ? <Loader /> : 'm/s'}
          disabled={climateLoadsLoading}
          columnPosition={{ start: 3, end: 5 }}
        />
        <hr className="col-span-full my-6 bg-grayscale-25" />
        <h2 className="col-span-full text-xl">{t('Terrängtyp')}</h2>
        <p className="col-span-full">
          {t(
            'Håll muspekaren över alternativen för att läsa mer om varje terrängtyp.'
          )}
        </p>
        <Radio
          name="terrain"
          options={formOptions.terrain}
          label={t('Terrängtyp')}
          className="mb-0"
        />

        {/* {conditions.configurationSystem === 'low' ? ( */}
        <>
          <hr className="col-span-full my-6 bg-grayscale-25" />
          <h2 className="col-span-full text-xl">{t('Krav')}</h2>
          <p className="col-span-full mb-2">
            {t(
              'Välj hur länge montaget ska hålla i de ökande klimatlasterna samt specificera önskad säkerhetsklass på montaget.'
            )}
          </p>
          <Select
            name="lifeSpan"
            label={t('Dimensionerande livslängd')}
            // placeholder={t('Välj dimension')}
            options={formOptions.lifeSpan}
            rules={{ required: { value: true, message: t('Obligatorisk') } }}
          />
          <NewSelect
            name="safetyClass"
            label={t('Säkerhetsklass')}
            options={formOptions.safetyClass}
            rules={{
              required: { value: true, message: t('Obligatorisk') }
            }}
          />
        </>
        <div className="col-span-full mt-6 flex justify-end">
          <Button onClick={handleSubmit(onSubmit)}>
            {t('Till ritverktyget')}
          </Button>
        </div>
      </form>
    </FormProvider>
  )
})

export default FormPositionPage
