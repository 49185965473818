import { KonvaEventObject } from 'konva/lib/Node'
import React, { useEffect, useState } from 'react'
import { Group, Rect, Image, Circle } from 'react-konva'
import undo from '~/assets/undo.svg'
import welandLogo from '~/assets/weland_logo_white.svg'
import { getImageElement, scaleMmToPixels } from '~/utils/configurator'

const getUndoIconImage = (): HTMLImageElement => {
  const image = new window.Image()
  image.src = undo
  return image
}

interface Props extends Panel {
  edit: boolean
  scale: number
  isAreaSelected: boolean
  isDragging: boolean
  isDrawingOrRedrawing: boolean
  system: string
  hoveringPanelMatch?: boolean
  panelAreaSize: Size
  setHoveringPanelRowColumn: React.Dispatch<
    React.SetStateAction<{
      row: number
      column: number
    } | null>
  >
  handleClick?: (event: KonvaEventObject<Event>) => void
  isConfigurationComplete: boolean
}

const Panel = React.memo(
  ({
    panelAreaUid,
    position,
    row,
    column,
    size,
    removed,
    fill,
    edit,
    scale,
    isAreaSelected,
    isDragging,
    isDrawingOrRedrawing,
    system,
    hoveringPanelMatch,
    panelAreaSize,
    setHoveringPanelRowColumn,
    isConfigurationComplete,
    handleClick
  }: Props) => {
    const strokeWidth = 1
    const scaledPosition = scaleMmToPixels<Position>(position)
    const scaledSize = scaleMmToPixels<Size>(size)
    const logoWidth = 56
    const logoHeight = 16

    const [hover, setHover] = useState(false)

    useEffect(() => {
      if (hover) {
        setHoveringPanelRowColumn({ row, column })
      } else {
        setHoveringPanelRowColumn(null)
      }
    }, [hover])

    const getRaisedEdgePosition = (
      column: number,
      panelWidth: number,
      panelAreaWidth: number
    ) => {
      if (panelAreaWidth < 0) {
        return column % 2 === 0 ? 0 : panelWidth - 9
      }
      return column % 2 !== 0 ? 0 : panelWidth - 9
    }

    removed = isDrawingOrRedrawing ? false : removed

    fill = isConfigurationComplete && !removed ? '#DEE0E0' : fill

    return (
      <Group
        x={scaledPosition.x}
        y={scaledPosition.y}
      >
        <Rect
          name="panel"
          panelAreaUid={panelAreaUid}
          removed={removed}
          width={
            column % 2 === 0 && removed
              ? scaledSize.width - strokeWidth + 9
              : scaledSize.width - strokeWidth
          }
          height={scaledSize.height - strokeWidth}
          fill={removed ? '#fff' : fill === undefined ? '#808080' : fill}
          onClick={handleClick}
          onMouseOver={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
        />
        <Rect
          width={scaledSize.width - strokeWidth}
          height={scaledSize.height - strokeWidth}
          visible={
            (hoveringPanelMatch === true || hover) &&
            isAreaSelected &&
            !isDragging &&
            !removed
          }
          fill={fill === '#EC848A' ? '#F6C2C4' : '#BFBFBF'}
          listening={false}
        />
        {system === 'east/west' &&
        !removed &&
        fill !== '#f2f2f2' &&
        fill !== '#EC848A' &&
        fill !== '#F6C2C4' ? (
          <Rect
            width={8}
            x={getRaisedEdgePosition(
              column,
              scaledSize.width,
              panelAreaSize.width
            )}
            height={scaledSize.height - strokeWidth}
            fill={isConfigurationComplete ? '#D2D3D5' : '#BFBFBF'}
            listening={false}
          />
        ) : null}
        {system === 'south' &&
        !removed &&
        fill !== '#f2f2f2' &&
        fill !== '#EC848A' &&
        fill !== '#F6C2C4' ? (
          <Rect
            height={9}
            x={0}
            width={scaledSize.width - strokeWidth}
            fill={'#BFBFBF'}
            listening={false}
          />
        ) : null}
        {!edit &&
        ((row % 2 === 0 && column % 2 === 0) ||
          (row % 2 !== 0 && column % 2 !== 0)) ? (
          <Image
            image={getImageElement(welandLogo)}
            width={logoWidth}
            height={logoHeight}
            offset={{
              x: logoWidth / 2,
              y: logoHeight / 2
            }}
            x={scaledSize.width / 2}
            y={scaledSize.height / 2}
            rotation={scaledSize.height > scaledSize.width ? -90 : 0}
            listening={false}
          />
        ) : null}
        {edit ? (
          <Group
            x={system === 'east/west' ? -4 : scaledSize.width / 2}
            y={scaledSize.height / 2}
            listening={false}
          >
            {removed && (column % 2 !== 0 || system !== 'east/west') ? (
              <>
                <Circle
                  width={Math.round(30 / scale)}
                  height={Math.round(30 / scale)}
                  fill={removed ? '#ffffff' : '#404040'}
                />
                <Image
                  image={getUndoIconImage()}
                  width={Math.round(14 / scale)}
                  height={Math.round(14 / scale)}
                  x={Math.round(-7 / scale)}
                  y={Math.round(-7 / scale)}
                  listening={false}
                />
              </>
            ) : null}
          </Group>
        ) : null}
      </Group>
    )
  }
)

export default Panel
