import { z, t } from '~/lib/i18n'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Button } from '~/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '~/components/ui/form'
import { Input } from '~/components/ui/input'

const FormSchema = z.object({
  name: z.string().min(1, {
    message: t('Namn måste vara minst 1 tecken långt')
  })
})

type Props = {
  roofName: string
  handleNameChange: (name: string) => void
}

export function RenameRoofForm({ roofName, handleNameChange }: Props) {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: roofName
    }
  })

  function onSubmit(data: z.infer<typeof FormSchema>) {
    handleNameChange(data.name)
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-10"
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('Namn')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('Namn på tak')}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-end">
          <Button type="submit">{t('Klar')}</Button>
        </div>
      </form>
    </Form>
  )
}
