import i18n from './i18n'
const { t } = i18n
import { useBoundStore } from '../store'
import { solarPanelClient } from '../http/api'
import { AxiosResponse } from 'axios'
import { getPanelAreasForAPI } from './panelAreaUtils'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { initialRoofState } from '~/slices/roofSlice'
import { v4 as uuidv4 } from 'uuid'
import { getRoofPoints, scaleMmToPixels } from '~/utils/configurator'
import * as Sentry from '@sentry/react'

export function cn(...classes: ClassValue[]) {
  return twMerge(clsx(classes))
}

export const adjustToClosestMultipleOf25 = (
  value: number,
  increase: boolean
) => {
  const remainder = Math.abs(value) % 25
  const closestMultiple = Math.floor(Math.abs(value) / 25) * 25
  const isMultipleOf25 = remainder === 0
  if (increase) {
    if (isMultipleOf25) {
      return value + 25
    } else {
      return value < 0 ? -closestMultiple : closestMultiple + 25
    }
  } else {
    if (isMultipleOf25) {
      return value - 25
    } else {
      return value < 0 ? -closestMultiple : closestMultiple
    }
  }
}

/**
 * Rounds a number to the specified precision.
 *
 * @param value - The number to round.
 * @param precision - The number of decimal places to round to.
 * @returns The rounded number.
 */
export const round = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

/**
 * Returns largest number in array with an even index.
 *
 * @param array {number[]}
 * @returns {number}
 */
export const getMaxOfEvenIndex = (array: number[]): number => {
  const evenIndices = array.filter((_, index) => index % 2 === 0)
  return Math.max(...evenIndices)
}

/**
 * Returns largest number in array with an odd index.
 *
 * @param array {number[]}
 * @returns {number}
 */
export const getMaxOfOddIndex = (array: number[]): number => {
  const oddIndices = array.filter((_, index) => index % 2)
  return Math.max(...oddIndices)
}

/**
 * Returns smallest number in array with an even index.
 *
 * @param array {number[]}
 * @returns {number}
 */
export const getMinOfEvenIndex = (array: number[]): number => {
  const evenIndices = array.filter((_, index) => index % 2 === 0)
  return Math.min(...evenIndices)
}

/**
 * Returns smallest number in array with an odd index.
 *
 * @param array {number[]}
 * @returns {number}
 */
export const getMinOfOddIndex = (array: number[]): number => {
  const oddIndices = array.filter((_, index) => index % 2)
  return Math.min(...oddIndices)
}

/**
 * Returns roof image scale used for sizing the labels based on image
 * size (920x550) plus the spacing for the distance labels (40px).
 *
 * @param roofWidth {number} total width of the roof
 * @returns {number}
 */
export const getPositionImageScale = (roofWidth: number) => {
  return 920 / roofWidth < 1 ? 920 / roofWidth : 1
}

export const getRoofName = (type: string) => {
  switch (type) {
    case 'gable':
      return t('Sadel')
      break
    case 'pent':
      return t('Pulpet')
      break
    case 'butterfly':
      return t('Motfalls')
      break
  }
}

/**
 * Saves the configuration by sending a POST request to the server.
 * @param handleResponse - A callback function to handle the response from the server.
 */
export const saveConfiguration = (
  handleResponse: (res: AxiosResponse) => void,
  handleFailedResponse: (res: AxiosResponse) => void
) => {
  const { uid, roofs, conditions, panelAreas, panelAreaSections } =
    useBoundStore.getState()
  solarPanelClient
    .post('/save', {
      uid,
      roofs: roofs,
      conditions,
      panelAreas: getPanelAreasForAPI(panelAreas),
      panelAreaSections
    })
    .then(handleResponse)
    .catch(handleFailedResponse)
}

/**
 * Converts an array of points into an array of coordinates.
 * Each point in the input array represents an (x, y) coordinate pair.
 * The resulting array contains arrays of coordinates, where each coordinate pair is multiplied by 10 (mm).
 *
 * @param points - An array of points representing (x, y) coordinate pairs.
 * @returns An array of coordinates, where each coordinate pair in millimeter scale.
 */
export const getCoordinatesFromPoints = (points: number[]): number[][] => {
  const coordinates: number[][] = []
  for (let i = 0; i < points.length; i += 2) {
    coordinates.push([points[i] * 10, points[i + 1] * 10])
  }
  return coordinates
}

export const getRoofPosition = (roofs: Roof[], roof?: Roof) => {
  const index = roofs.findIndex((r) => r.uid === roof?.uid)

  if (index === -1 && roofs.length === 0) {
    return { x: 0, y: 0 }
  }

  const previousRoof =
    roofs[index === -1 && roofs.length > 1 ? roofs.length - 1 : 0]

  const prevPoints = getRoofPoints(previousRoof.shape, {
    a: previousRoof.measurementA,
    b: previousRoof.measurementB,
    c: previousRoof.measurementC
  })

  // let minXValue = 0

  // if (roof) {
  //   const points = getRoofPoints(roof.shape, {
  //     a: roof.measurementA,
  //     b: roof.measurementB,
  //     c: roof.measurementC
  //   })

  //   minXValue = Math.abs(
  //     Math.min(...points.filter((_, index) => index % 2 === 0))
  //   )
  // }

  const prevXValues = prevPoints.filter((_, index) => index % 2 === 0)

  const positionX =
    previousRoof.position.x +
    scaleMmToPixels<number>(Math.max(...prevXValues) * 10)

  const gap = index === 0 ? 0 : 96

  return { x: positionX + gap, y: previousRoof.position.y }
}

export const setRoofsPositions = (roofs: Roof[]) => {
  const modifiedRoofs = structuredClone(roofs)
  let x = 0
  modifiedRoofs.forEach((modifiedRoof, index) => {
    let nextMinXValue = 0
    const nextRoof = modifiedRoofs[index + 1]
    if (nextRoof) {
      const nextPoints = getRoofPoints(nextRoof.shape, {
        a: nextRoof.measurementA,
        b: nextRoof.measurementB,
        c: nextRoof.measurementC
      })
      const nextXValues = nextPoints.filter((_, index) => index % 2 === 0)
      nextMinXValue = Math.abs(Math.min(...nextXValues)) * 10
    }
    modifiedRoof.position = { x, y: 0 }
    const points = getRoofPoints(modifiedRoof.shape, {
      a: modifiedRoof.measurementA,
      b: modifiedRoof.measurementB,
      c: modifiedRoof.measurementC
    })
    const xValues = points.filter((_, index) => index % 2 === 0)
    const maxXValue = Math.max(...xValues) * 10
    x += scaleMmToPixels<number>(maxXValue + nextMinXValue) + 96
  })

  return modifiedRoofs
}

export const getRoofPropertiesFormDevDefaultValues = () => {
  return import.meta.env.DEV
    ? {
        ridgeHeight: 5,
        roofSlope: 2,
        roofMeasurementA: 16,
        roofMeasurementB: 10
      }
    : {}
}

export const getNewRoof = (system: string) => {
  const roofUid = uuidv4()

  const { roofs } = useBoundStore.getState()

  let roof = {
    ...initialRoofState[0],
    name: `${t('Takyta')} ${roofs.length + 1}`,
    uid: roofUid,
    position: getRoofPosition(roofs.filter((roof) => !roof.deleted))
  }

  switch (system) {
    case 'low':
      return {
        ...roof,
        covering: 'flat',
        attachment: 'sealing_plate_flat',
        type: 'pent',
        shape: 'rectangle',
        slope: 0,
        orientationFromSouth: 0
      }
    default:
      return {
        ...roof
      }
  }
}

export const getRoofSectionStatus = (roofUid: string) => {
  const {
    isPositionDataValid,
    isRoofMaterialDataValidRoofSettings,
    isRoofPropertiesDataValidRoofSettings
  } = useBoundStore.getState()

  if (
    isPositionDataValid &&
    isRoofMaterialDataValidRoofSettings[roofUid] &&
    isRoofPropertiesDataValidRoofSettings[roofUid]
  ) {
    return 'valid'
  } else {
    return 'invalid'
  }
}

export const getRoofCoordinates = (roof: Roof) => {
  const { shape, measurementA, measurementB, measurementC = 0 } = roof
  return getCoordinatesFromPoints(
    getRoofPoints(shape, {
      a: measurementA,
      b: measurementB,
      c: measurementC
    })
  )
}

export const duplicateRoof = (roofUid: string) => {
  const {
    roofs,
    panelAreas,
    panelAreaSections,
    addRoof,
    setPanelAreas,
    setCurrentRoofUid,
    setIsPositionDataValid,
    setIsRoofMaterialDataValid,
    setIsRoofPropertiesDataValid,
    setShowConditions,
    setPanelAreaSections
  } = useBoundStore.getState()

  const uid = uuidv4()

  const roof = roofs.find((roof) => roof.uid === roofUid)

  if (roof === undefined) {
    return
  }

  const duplicatedRoof = {
    ...roof,
    uid,
    name: `${roof.name} ${t('Kopia')}`,
    position: getRoofPosition(roofs)
  }

  let duplicatePanelAreaSections: PanelAreaSection[] = []

  const duplicatedPanelAreas = panelAreas
    .filter((panelArea) => panelArea.roofUid === roofUid)
    .map((panelArea) => {
      const panelAreaUid = uuidv4()

      duplicatePanelAreaSections = [
        ...duplicatePanelAreaSections,
        ...panelAreaSections
          .filter((section) => section.panelAreaUid === panelArea.uid)
          .map((section) => {
            return {
              ...section,
              uid: uuidv4(),
              panelAreaUid,
              roofUid: uid
            }
          })
      ]

      const panels = panelArea.panels.map((panel) => {
        return {
          ...panel,
          uid: uuidv4(),
          panelAreaUid: panelAreaUid
        }
      })
      return {
        ...panelArea,
        uid: panelAreaUid,
        roofUid: uid,
        panels
      }
    })

  addRoof(duplicatedRoof)
  setPanelAreas([...panelAreas, ...duplicatedPanelAreas])
  setPanelAreaSections([...panelAreaSections, ...duplicatePanelAreaSections])
  setCurrentRoofUid(uid)
  setIsPositionDataValid(true)
  setIsRoofMaterialDataValid(true)
  setIsRoofPropertiesDataValid(true)
  setShowConditions(false)
}

export enum DistanceValidation {
  TooSmall,
  Ok,
  TooLarge
}

/** Validates rail distance based on height and width of panel. Also takes system and mounting in account. */
export const validateRailDistance = (
  panelWidth: number,
  panelHeight: number,
  systemAndMounting: SystemAndMounting,
  railDistance: number
): DistanceValidation => {
  /**
   * If rails are horizontal, that is to say, 90 degrees tilted for parallel or if rails go from east -> west (low sloping).
   * Then check height, otherwise check width.
   */
  const axisToCheck =
    systemAndMounting.mounting.startsWith('90-') ||
    systemAndMounting.system == 'east/west'
      ? 'height'
      : 'width'

  const FIFTY_PERCENT = 0.5
  const NINETY_PERCENT = 0.9

  let minRailDistance =
    axisToCheck === 'height'
      ? panelHeight * FIFTY_PERCENT
      : panelWidth * FIFTY_PERCENT
  let maxRailDistance =
    axisToCheck === 'height'
      ? panelHeight * NINETY_PERCENT
      : panelWidth * NINETY_PERCENT

  if (railDistance > maxRailDistance) return DistanceValidation.TooLarge
  if (railDistance < minRailDistance) return DistanceValidation.TooSmall

  return DistanceValidation.Ok
}

/**
 * Infer default rail distance based on panel height, width, type of system and mounting.
 * Can be used for both low sloping and parallel systems.
 */
export const inferRailDistanceFromPanel = (
  panelWidth: number,
  panelHeight: number,
  systemAndMounting: SystemAndMounting
): number => {
  if (
    systemAndMounting.mounting.startsWith('90-') ||
    systemAndMounting.system == 'east/west'
  ) {
    return round(panelHeight * 0.6, 0)
  } else {
    return round(panelWidth * 0.6, 0)
  }

  return 600
}

/** A thin, semantic wrapper around sessionStorage to check if key exists  */
export const inSessionStorage = (key: string): boolean => {
  return sessionStorage.getItem(key) !== null
}

export const removeFromSessionStorage = (key: string) => {
  sessionStorage.removeItem(key)
}

/**
 * Resets rail distance to default based on panel dimensions.
 * If a reset function is provided, it will be called with the calculated default rail distance.
 * The calculated default rail distance is based on the panel dimensions and the system and mounting type.
 */
export const resetRailDistance = (
  panelWidth: number,
  paneHeight: number,
  systemAndMounting: SystemAndMounting,
  resetFn?: (calculatedRailDistance: number) => void
): void => {
  const defaultRailDistance = inferRailDistanceFromPanel(
    panelWidth,
    paneHeight,
    systemAndMounting
  )
  if (resetFn) {
    resetFn(defaultRailDistance)
  }
}

/**
 * Determines whether a new UID should be generated based on the save panel flag and the current UID.
 *
 * @param doSavePanel - A boolean indicating whether the panel should be saved.
 * @param uid - The current UID of the panel.
 * @returns A boolean indicating whether a new UID should be generated.
 */
export const shouldGenerateNewPanelUid = (
  doSavePanel: boolean,
  uid: string
) => {
  if (doSavePanel && uid !== '') {
    return false
  } else if (doSavePanel && uid === '') {
    return true
  }

  return false
}

/**
 * Calculates the weight of a solar panel based on its width and height.
 *
 * The weight is calculated using the formula:
 * (width / 1000) * (height / 1000) * 13, and then rounded to the nearest integer.
 *
 * @param width - The width of the solar panel in millimeters.
 * @param height - The height of the solar panel in millimeters.
 * @returns The calculated weight of the solar panel in kilograms, or 0 if the width or height is non-positive.
 */
export const calculatePanelWeight = (width: number, height: number) => {
  if (width > 0 && height > 0) {
    return Math.round((width / 1000) * (height / 1000) * 13)
  } else {
    return 0
  }
}

type SavedPanelResponse = {
  panels: SavedPanel[]
}
/** Sends a save request to save panels in api */
export const savePanel = (
  panelInfoData: Partial<PanelInfo>,
  handlePanelResponse?: (res: AxiosResponse<SavedPanelResponse>) => void,
  handleFailedResponse?: (error: AxiosResponse) => void
) => {
  solarPanelClient
    .post('/panels/save', {
      panels: [panelInfoData]
    })
    .then((res) => (handlePanelResponse ? handlePanelResponse(res) : null))
    .catch((error) => {
      Sentry.captureException(error)
      handleFailedResponse ? handleFailedResponse(error) : null
    })
}

/**
 * Adds a project reference to the local storage under the key 'guestProjectReferences'.
 * If there are existing references, it appends the new reference to the list.
 * If no references exist, it initializes the list with the new reference.
 *
 * @param reference - The project reference to be added.
 */
export const addProjectReferenceToLocalStorage = (reference: string) => {
  const storedGuestProjectReferences = localStorage.getItem(
    'guestProjectReferences'
  )
  const guestProjectReferences =
    storedGuestProjectReferences !== null
      ? JSON.parse(storedGuestProjectReferences)
      : []
  localStorage.setItem(
    'guestProjectReferences',
    JSON.stringify([...guestProjectReferences, reference])
  )
}

export const handleSaveResponseData = (res: AxiosResponse) => {
  const { user, setReference, setPdfUrl } = useBoundStore.getState()
  if (user === null) {
    addProjectReferenceToLocalStorage(res.data.reference)
  }
  setReference(res.data.reference)
  setPdfUrl(res.data.pdfUrl)
}

export const isRoofNotApproved = (roofUid: string) => {
  const { panelAreaSections } = useBoundStore.getState()
  return panelAreaSections.some(
    (section) => section.roofUid === roofUid && !section.result.isApproved
  )
}

export const deleteRoof = (roofUid: string) => {
  const {
    roofs,
    panelAreas,
    panelAreaSections,
    panelSectionImagesData,
    setRoofs,
    setPanelAreas,
    setPanelAreaSections,
    setCurrentRoofUid,
    setPanelSectionImagesData
  } = useBoundStore.getState()

  const newActiveRoofUid =
    roofs[0].uid !== roofUid ? roofs[0].uid : roofs[1].uid

  const panelAreaSectionsToDelete = panelAreaSections.filter(
    (section) => section.roofUid === roofUid
  )

  const updatedPanelSectionImagesData = panelSectionImagesData.filter(
    (imageData) =>
      !panelAreaSectionsToDelete.some(
        (section) => section.uid === imageData.uid
      )
  )

  setPanelSectionImagesData(updatedPanelSectionImagesData)
  setCurrentRoofUid(newActiveRoofUid)
  setPanelAreaSections(
    panelAreaSections.filter((section) => section.roofUid !== roofUid)
  )
  setPanelAreas(panelAreas.filter((panelArea) => panelArea.roofUid !== roofUid))
  setRoofs(roofs.filter((roof) => roof.uid !== roofUid))
}

/**
 * Generates a URL to fetch a CSV file for a given reference.
 *
 * @param reference - The reference identifier for the CSV file.
 * @returns The complete URL to fetch the CSV file.
 */
export const getCsvUrl = (reference: string, includePrices: boolean) => {
  const url = new URL(
    `${import.meta.env.VITE_API_SOLAR_PANELS_BASE_URL}/csv/${reference}`
  )
  url.searchParams.set('hide-prices', includePrices ? 'false' : 'true')

  return url.toString()
}
