import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import sv from '~/../locales/sv/translation.json'
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import zodSvTranslation from 'zod-i18n-map/locales/sv/zod.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'sv',
    resources: {
      sv: {
        translation: sv,
        zod: zodSvTranslation
      }
    },
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })
z.setErrorMap(zodI18nMap)

export { z }

export const { t } = i18n

export default i18n
